@import './fonts/fonts.css';

body {
  font-family: 'Hevelica', 'SofiaPro', 'Avenir', 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Tiempos', monospace;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  text-align: center;
  color: #ffffff;
  font-size: 32px;
}

p {
  color: #ffffff;
  text-align: center;
}